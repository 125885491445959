import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';

export default function MonitoringGeneralInfo({ visible, device }) {
  const [deviceInfo, setDeviceInfo] = useState({});
  const fetchGeneralInfo = async () => {
    console.log(device);
    setDeviceInfo(device);
  };

  useEffect(() => {
    if (visible) {
      fetchGeneralInfo();
    }
  }, [visible, device]);

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Device Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>ID:</strong> {deviceInfo.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Device Name:</strong> {deviceInfo.device_name || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Device IPv4:</strong> {deviceInfo.device_ip4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Hostname:</strong> {deviceInfo.hostname || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Last Online State:</strong> {deviceInfo.last_online_state || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={1}>
          <Typography variant="h6" gutterBottom>
            Agent Information
          </Typography>
          <Typography variant="body1">
            <strong>Pooler Agent:</strong> {deviceInfo.pooler_agent || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Agent Status:</strong> {deviceInfo.agent_status || "N/A"}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
