import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { format, differenceInDays, addDays } from 'date-fns';
import { orangeColor } from '../../css/common_sx';
const reservationsData = {
  reservations: [
    {
      reservation_id: "113591",
      listing_property_name: "Bastion's F02",
      date_from: "2024-11-19",
      date_to: "2024-11-23",
    },
    {
      reservation_id: "113593",
      listing_property_name: "Fleur 4BD 2/3/4",
      date_from: "2024-11-26",
      date_to: "2024-11-30",
    },
    {
      reservation_id: "113590",
      listing_property_name: "Valletta SU PH",
      date_from: "2025-02-27",
      date_to: "2025-03-04",
    },
    {
      reservation_id: "113590",
      listing_property_name: "Valletta SU PH",
      date_from: "2025-03-04",
      date_to: "2025-03-10",
    }
    // ... more reservations
  ],
};

const CalendarTable = () => {
  // Parse reservations and calculate date range
  const reservations = reservationsData.reservations;
  const allDates = reservations.flatMap((r) => [new Date(r.date_from), new Date(r.date_to)]);
  const minDate = new Date(Math.min(...allDates.map((d) => d.getTime())));
  const maxDate = new Date(Math.max(...allDates.map((d) => d.getTime())));

  // Generate list of dates for table header
  const dateList = [];
  for (let d = minDate; d <= maxDate; d = addDays(d, 1)) {
    dateList.push(d);
  }

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" margin="5px">
      <Box display="flex" flexDirection="row" alignItems="center" mb={1} bgcolor={orangeColor} p={1} borderRadius={1}>
      </Box>
      <Box position="relative" width="100%" height="100%">
        <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  {dateList.map((date) => (
                    <TableCell key={date.toISOString()}>{format(date, "MMM d")}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.map((reservation) => {
                  const reservationStart = new Date(reservation.date_from);
                  const reservationEnd = new Date(reservation.date_to);
                  const startIndex = differenceInDays(reservationStart, minDate);
                  const spanLength = differenceInDays(reservationEnd, reservationStart) + 1;

                  return (
                    <TableRow key={reservation.reservation_id}>
                      <TableCell width='150px'>{reservation.listing_property_name}</TableCell>
                      {dateList.map((_, index) => {
                        if (index === startIndex) {
                          return (
                            <TableCell key={index} colSpan={spanLength} style={{ backgroundColor: 'red' }}>
                              Reservation
                            </TableCell>
                          );
                        }
                        if (index > startIndex && index < startIndex + spanLength) {
                          return null; // Skip cells covered by colspan
                        }
                        return <TableCell key={index}></TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box></Box></Box>
  );
};

export default CalendarTable;

