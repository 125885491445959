import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Divider,
  CircularProgress,
  Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ItemsTable from './items_table';
import { orangeColor } from '../../../css/common_sx';
import axios from 'axios';
import LoadingBackdrop from '../../../common/loading_backdrop';
import { debounce } from 'lodash';
import ViewDocumentItemDetail from './view_document_item_detail';
export default function CRMViewDocument({ open, onClose, document, onSave, onError }) {
  const [documentItems, setDocumentItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadingBackdrop, setShowLoadingBackdrop] = useState(false);
  const [availableNewStatuses, setAvailableNewStatuses] = useState([]);
  const [showDocumentItemDialog, setShowDocumentItemDialog] = useState(false);
  const [selectedDocumentItem, setSelectedDocumentItem] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    document_no: '',
    created_by: '',
    created_at: '',
    partner_reference: '',
    document_type_name: '',
    source_warehouse_name: '',
    destination_warehouse_name: '',
    required_amount: false,
    status: '',
    status_name: ''
  });

  const fetchAvailableNewStatuses = async () => {
    try {
      setAvailableNewStatuses([]);
      const response = await axios.get(`/Office/CRM/Documents/possible_statuses/${document.id}`);
      setAvailableNewStatuses(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDocumentItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/Office/CRM/DocumentItems/${document.id}`);
      setDocumentItems(response.data.map((item) => ({
        ...item,
        total: item.gross * item.quantity
      })));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setDocumentItems([]);
      fetchDocumentItems();
      fetchAvailableNewStatuses();
      if (document) {
        setFormData({
          id: document.id || '',
          document_no: document.document_no || '',
          created_by: document.created_by || '',
          created_at: document.created_at ? new Date(document.created_at).toLocaleString() : '',
          partner_reference: document.partner_reference || '',
          document_type_name: document.document_type_name || '',
          source_warehouse_name: document.source_warehouse_name || '',
          destination_warehouse_name: document.destination_warehouse_name || '',
          deliveryFrom: {
            id: -1,
            name: ''
          },
          deliveryTo: {
            id: -1,
            name: ''
          },
          required_amount: document.require_amount || false,
          status: parseInt(document.status || '-1'),
          status_name: document.status_name || '',
          description: document.description || ''
        });
        console.log(document);
      }
    }
  }, [open, document]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setFormData((prevState) => {
      if (name === 'status') {
        const status_name = availableNewStatuses.find((status) => status.status_value === value).status_name;
        return { ...prevState, status_name };
      }
      return prevState;
    });
  };

  const handleSave = async () => {
    try {
      axios.put(`/Office/CRM/Documents/${document.id}`, formData)
        .then((res) => {
          if (res.status === 200) {
            console.log('Document updated successfully');
            onSave(formData);
            onClose(0);
          }
        })
        .catch((err) => {
          onError('Failed to update document \n' + err.response.data.message);
          onClose(0);
        });
      console.log('formDataToSend:', formData);
    } catch (error) {
      console.error('Failed to save document:', error);
    }
  };

  const handleDeleteDocument = async () => {
    setShowLoadingBackdrop(true);
    try {
      axios.delete(`/Office/CRM/Documents/${document.id}`)
        .then((res) => {
          if (res.status === 200) {
            setShowLoadingBackdrop(false);
            onClose(1);
          }
        }).catch((err) => {
          console.error(err);
        })
      onClose();
    } catch (error) {
      setShowLoadingBackdrop(false);
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={() => {
      onClose(null);
    }} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: orangeColor, color: '#fff', textAlign: 'center', fontWeight: 'bold' }}>
        View Document
      </DialogTitle>
      <DialogContent dividers>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9} >
              <TextField
                label="Document Number"
                name="document_no"
                value={formData.document_no}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3} >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="filled"
                  value={formData.status}
                  name="status"
                  onChange={handleInputChange}
                >
                  {formData.status && <MenuItem disabled key={status} value={formData.status}>{formData.status_name}</MenuItem>}
                  {availableNewStatuses.map((status) => (
                    <MenuItem key={status.status_value} value={status.status_value} >{status.status_name}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Created By"
                name="created_by"
                value={formData.created_by}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Created At"
                name="created_at"
                value={formData.created_at}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            {formData.partner_reference && (
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Document Partner Reference"
                  name="partner_reference"
                  value={formData.partner_reference}
                  fullWidth
                  disabled
                  variant="filled"
                  InputProps={{ sx: { borderRadius: 1 } }}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3}>
              <TextField
                label="Document Type"
                name="document_type_name"
                value={formData.document_type_name}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Source Warehouse"
                name="source_warehouse_name"
                value={formData.source_warehouse_name}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Destination Warehouse"
                name="destination_warehouse_name"
                value={formData.destination_warehouse_name}
                fullWidth
                disabled
                variant="filled"
                InputProps={{ sx: { borderRadius: 1 } }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={24}>
              <TextField
                value={formData.description}
                fullWidth multiline
                inputProps={{ readOnly: true }}
                rows={4}
                variant="outlined" />
            </Grid>
          </Grid>
        </Paper>
        <Divider sx={{ my: 2 }} />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 200 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box overflow='auto'>
            <ItemsTable
              items={documentItems}
              allowDecrement={false}
              requireAmount={formData.required_amount}
              showWarehouseName={true}
              allowRemove={false}
              allowAmountChange={false}
              onClickItemInfo={(item) => {
                setSelectedDocumentItem({ document: formData, ...item });
                setShowDocumentItemDialog(true);
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Box>
          <Button
            variant="text"
            onClick={handleDeleteDocument}
            sx={{ backgroundColor: orangeColor, color: '$fff', textTransform: 'none' }}
            disabled={documentItems.length !== 0}
          >
            Delete document
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: 2, bgcolor: orangeColor, color: '#fff', borderRadius: 2 }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
      <LoadingBackdrop open={showLoadingBackdrop} text="Deleting Document" />
      <ViewDocumentItemDetail
        open={showDocumentItemDialog}
        onClose={() => setShowDocumentItemDialog(false)}
        item={selectedDocumentItem} />
    </Dialog>
  );
}

