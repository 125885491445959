import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Divider,
  CircularProgress,
  Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import { orangeColor } from '../../../css/common_sx';
import PhysicalItemsTable from './physical_items_table';
import PickPhysicalItemDialog from './pick_physical_item';

export default function ViewDocumentItemDetail({ item, open, onClose, allowEdit = false }) {
  const [showPhysicalItemPicker, setShowPhysicalItemPicker] = useState(false);
  const [itemFormData, setItemFormData] = useState({});
  useEffect(() => {
    if (!open) return;
    console.log('Item Details of: ', item);
    setItemFormData({
      id: item?.id,
      item_name: item?.item_name,
      warehouse_name: item?.warehouse_name,
      quantity: item?.quantity,
      gross_price: item?.gross_price,
      physical_items: item?.physical_items ?? [],
      document: item?.document
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={() => {
      onClose(null);
    }} fullWidth maxWidth="md">
      <DialogTitle sx={{ bgcolor: orangeColor }}>View Document Item {item?.item_name} </DialogTitle>
      <DialogContent dividers>
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Item Name"
                value={item?.item_name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Warehouse"
                value={item?.warehouse_name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Quantity"
                value={item?.quantity}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Gross Price"
                value={item?.gross_price}
                fullWidth
                disabled
              />
            </Grid>

          </Grid>
        </Paper>
        <PhysicalItemsTable
          items={itemFormData.physical_items}
          allowAdd={allowEdit}
          onRemove={(item) => {
            setItemFormData({
              ...itemFormData,
              physical_items: itemFormData.physical_items.filter(i => i.physical_item_id !== item.physical_item_id)
            });
          }}
          onAddNewPhysicalItem={() => setShowPhysicalItemPicker(true)}
        />
        <PickPhysicalItemDialog
          open={showPhysicalItemPicker}
          onClose={() => setShowPhysicalItemPicker(false)}
          srcWarehouse={itemFormData?.document?.deliveryFrom?.id}
          onSelect={(item) => {
            console.log('selected item', item);
            setShowPhysicalItemPicker(false);
            setItemFormData({
              ...itemFormData,
              physical_items: [...itemFormData.physical_items, item]
            });
          }} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          onClick={() => onClose(null)}>Close</Button>
        <Button
          variant="outlined"
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          onClick={() => onClose(itemFormData)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
