import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Divider,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Drawer,
  Grid,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
import { StorageRounded } from '@mui/icons-material';
import axios from 'axios';
import { orangeColor } from '../../css/common_sx';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { searchFunction } from '../../common/search_function';
import { debounce } from 'lodash';

const CRMCreateItem = ({ open, onClose, onSave, item }) => {
  const [tabValue, setTabValue] = useState(0);
  const [itemTypes, setItemTypes] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    type: '',
    description: '',
    photos: [],
  });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    if (item) {
      setFormData({
        id: item.id,
        name: item.name,
        sku: item.sku,
        type: item.type,
        description: item.description,
        photos: item.images
      });
    }
  }, [open]);

  useEffect(() => {
    axios.get('/Office/CRM/ItemTypes')
      .then(response => {
        setItemTypes(response.data);
      })
      .catch(error => {
        console.error('Error fetching item types:', error);
        setSnackbarMessage('Error fetching item types');
        setSnackbarOpen(true);
      });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);

    try {
      const response = await axios.post('/upload/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const filePath = response.data.filePath;
      setFormData(prevFormData => ({
        ...prevFormData,
        photos: [...prevFormData.photos, filePath]
      }));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setImageModalOpen(true);
  };

  const handleImageDelete = (imageToDelete) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      photos: prevFormData.photos.filter(image => image !== imageToDelete)
    }));
  };

  const handleSave = () => {
    const dataToSend = {
      item_name: formData.name,
      sku: formData.sku,
      item_type_id: formData.type,
      description: formData.description,
      images: formData.photos
    };

    onSave(dataToSend);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle bgcolor={orangeColor}>{item ? `Edit Item: ${formData.name} ${formData.id}` : 'Create New Item'}</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="General" />
            <Tab label="Locations" />
            <Tab label="Access" />
          </Tabs>
        </Box>
        <Divider sx={{ my: 2 }} />

        {tabValue === 0 && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  margin="normal"
                  label="Type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  renderValue={(selected) =>
                    selected ? itemTypes.find(type => type.id === selected)?.type_name : "Select Type"
                  }
                >
                  {itemTypes.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.type_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  label="SKU"
                  name="sku"
                  value={formData.sku}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Photos</Typography>
                <Button variant="contained" component="label">
                  Upload Photo
                  <input
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                </Button>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                  {formData.photos.map((photo, index) => (
                    <Box key={index} sx={{ position: 'relative', display: 'inline-block' }}>
                      <img
                        src={photo}
                        alt={`Uploaded ${index + 1}`}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => handleImageClick(photo)}
                      />
                      <IconButton
                        sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                        onClick={() => handleImageDelete(photo)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 1 && (
          <Box>
            <Typography>Locations</Typography>
            {/* Add form fields for locations here */}
          </Box>
        )}

        {tabValue === 2 && (
          <Box>
            <Typography>Access</Typography>
            {/* Add form fields for access here */}
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button onClick={handleSave} disabled={item} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </DialogContent>

      {/* Image Modal */}
      <Modal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box sx={{ position: 'relative', width: '80%', height: '80%' }}>
          <img
            src={`https://images.leviathan.systems/secure/plain/s3://api-upload-folder/${selectedImage}`}
            alt="Enlarged"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
            onClick={() => setImageModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Dialog>
  );
};


const CRMItems = () => {
  const [items, setItems] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Items per page
  const [showCreateItem, setShowCreateItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const fetchItems = async () => {
    axios.get('/Office/CRM/Items')
      .then(response => {
        const fetchedItems = response.data.map(item => ({
          id: item.id,
          name: item.item_name,
          item_type: item.item_type,
          description: item.description,
          picture: item.pic,
          sku: item.sku,
          tags: item.tags ? item.tags.map(tag => tag.replace(/'/g, '')) : [],
          createDate: item.created_at,
          images: item.images
        }));
        setItems(fetchedItems);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching data');
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleNewItem = (item) => {
    console.log('New item:', item);
    axios.post('/Office/CRM/Items', item).then(response => {
      if (response.status === 200) {
        setSnackbarMessage('Item created successfully');
        setSnackbarOpen(true);
        setShowCreateItem(false);
        fetchItems();
      }
    }).catch(error => {
      console.error('Error creating item:', error);
      setSnackbarMessage('Error creating item');
      setSnackbarOpen(true);
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredItems = useMemo(() => {
    return searchFunction(globalSearch, ['name'], items);
  }, [globalSearch, items]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      {/* Filter Component */}
      <Box display="flex" justifyContent="space-between" mb={2} bgcolor={orangeColor} p={2} borderRadius={1}>
        <TextField
          label="Search by Name"
          variant="outlined"
          fullWidth
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowCreateItem(true)}
          sx={{ mx: 2 }}
        >
          Add Item
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align='center'>SKU</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Description</TableCell>
              <TableCell align='center'>Tags</TableCell>
              <TableCell align='center'>Type</TableCell>
              <TableCell align='center'>Picture</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.sku}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {row.tags.map(tag => (
                      <Chip size="small" variant="outlined" key={tag} label={tag} sx={{ marginLeft: '5px' }} />
                    ))}
                  </TableCell>
                  <TableCell>
                    {row.item_type}
                  </TableCell>
                  <TableCell>
                    <img
                      src={row.images.length > 0 ? row.images[0] : ''}
                      alt={row.name}
                      style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0, // Remove extra padding
                      }}
                      onClick={() => {
                        setSelectedItem(row);
                        setShowCreateItem(true);
                      }}>
                      <InfoIcon fontSize='small' />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0, // Remove extra padding
                      }}
                      onClick={() => handleDelete(row.id)}>
                      <DeleteRounded fontSize='small' />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0, // Remove extra padding
                      }}
                    >
                      <StorageRounded fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[30, 60, 100]}
        component="div"
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CRMCreateItem open={showCreateItem} onClose={() => setShowCreateItem(false)} onSave={handleNewItem} item={selectedItem} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box >
  );
};


export default CRMItems;

