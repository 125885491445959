import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, TableRow, TableCell, TablePagination, Tab, Tabs, Typography, IconButton, Checkbox, Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import { debounce } from 'lodash';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
import MonitoringGeneralInfo from './general_info';
import MonitoringDPI from './dpi';
import ArticleIcon from '@mui/icons-material/Article';
import { DensitySmall } from '@mui/icons-material';
import ComputerIcon from '@mui/icons-material/Computer';
import AddIcon from '@mui/icons-material/Add';
import AddNewDeviceDialog from './devices/addNewdevice';
const MonitoringView = () => {
  const [deviceType, setDeviceType] = useState(1);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [globalSearch, setGlobalSearch] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [openAddNewDevice, setOpenAddNewDevice] = useState(false);
  const fetchDevices = async (type) => {
    setLoading(true);
    try {
      axios.get(`/LeviathanMonitoring/Devices?type=${type}`).then((response) => {
        setDevices(response.data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300),
    []
  );

  const filteredDevices = useMemo(() => {
    return searchFunction(globalSearch, [
      'device_name',
    ], devices);
  }, [globalSearch, devices]);

  useEffect(() => {
    fetchDevices(deviceType);
  }, [deviceType]);

  useEffect(() => {
    setDeviceType(1);
  }, []);

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <MonitoringGeneralInfo device={selectedDevice} visible={true} />;
      case 1:
        return <Typography variant="body2">Events related to the device are displayed here.</Typography>;
      case 2:
        return <Typography variant="body2">DCI-specific data is shown here.</Typography>;
      case 3:
        return <MonitoringDPI visible={true} device={selectedDevice} />;
      case 4:
        return <Typography variant="body2">Interfaces of the device are listed here.</Typography>;
      case 5:
        return <Typography variant="body2">Syslog entries are displayed here.</Typography>;
      default:
        return null;
    }
  };

  const columns = [
    'Device ID',
    'Device Name',
    'Device IP4',
    'Device Hostname',
    'Last Online Status',
    'Pooler Agent',
    'Agent Status'
  ];

  return (
    <Box p={1} display="flex" flexDirection="row" flex="1" width="cal(100% - 2px)" height="100%" margin="5px">
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
        <Box display="flex" height='25px' justifyContent="flex-start" alignItems="center" mb={1} p={1} borderRadius={2} boxShadow={3} bgcolor={orangeColor} sx={{ height: '60px' }}>
          <IconButton sx={{ bgcolor: deviceType == 1 ? 'greenyellow' : 'transparent', padding: 1 }} onClick={() => setDeviceType(1)}><ComputerIcon /></IconButton>
          <IconButton sx={{ bgcolor: deviceType == 2 ? 'greenyellow' : 'transparent', padding: 1 }} onClick={() => setDeviceType(2)}><ArticleIcon /></IconButton>
          <IconButton sx={{ bgcolor: deviceType == 3 ? 'greenyellow' : 'transparent', padding: 1 }} onClick={() => setDeviceType(3)}><DensitySmall /></IconButton>
          <TextField
            label="Search"
            fullWidth
            onChange={(e) => handleSearchChange(e.target.value)}
            sx={{ bgcolor: 'white', borderRadius: 1, mx: 2, p: 0 }}
          />
          <IconButton sx={{ padding: 1 }} onClick={() => {
            setOpenAddNewDevice(true);
          }}><AddIcon /></IconButton>
        </Box>
        <TableGrid columns={columns} loading={loading}>
          {filteredDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((device) => (
              <TableRow
                onClick={() => setSelectedDevice(device)}
                hover
                style={{ cursor: 'pointer' }}
                key={device.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell width='15px'>{device.id}</TableCell>
                <TableCell>{device.device_name}</TableCell>
                <TableCell>{device.device_ip4}</TableCell>

                <TableCell>{device.hostname}</TableCell>
                <TableCell>{device.last_online_status}</TableCell>
                <TableCell>{device.pooler_agent}</TableCell>
                <TableCell>{device.agent_status}</TableCell>
              </TableRow>
            ))}
        </TableGrid>
        <TablePagination
          rowsPerPageOptions={[30, 50, 60]}
          component="div"
          sx={{ ...CommonSX, marginTop: '10px' }}
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {selectedDevice && (
        <Box flex={4} display="flex" flexDirection="column" width="100%" height="100%" mr={1} ml={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={1} mb={1} borderRadius={2} boxShadow={3} bgcolor={orangeColor}>
            <Typography variant="subtitle1">Details for Device: {selectedDevice.device_name}</Typography>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{ minHeight: '30px', fontSize: '8px', '& .MuiTab-root': { minHeight: '30px', fontSize: '0.8rem' } }}
            >
              <Tab label="General" />
              <Tab label="Events" />
              <Tab label="DCI" />
              <Tab label="DPI" />
              <Tab label="Interfaces" />
              <Tab label="Syslog" />
            </Tabs>

            <IconButton onClick={() => setSelectedDevice(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {renderTabContent()}
        </Box>
      )}
      <AddNewDeviceDialog open={openAddNewDevice} onClose={() => setOpenAddNewDevice(false)} />
    </Box>
  );
};

export default MonitoringView;

