import { orangeColor } from "../css/common_sx"
import { Button } from "@mui/material"
export const DialogSaveButton = ({ onClick, disabled = false }) => {
  return (
    <Button
      variant="contained"
      sx={{ marginRight: 2, bgcolor: orangeColor, color: '#fff', borderRadius: 2 }}
      onClick={onClick}
      disabled={disabled}>Save</Button>
  )
}

export const DialogCancelButton = ({ onClick, disabled = false }) => {
  return (
    <Button onClick={onClick}
      disabled={disabled}
      sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
      variant="outlined">Cancel</Button>
  );
}
