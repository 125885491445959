import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Typography,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid
} from '@mui/material';
import axios from 'axios';
import mqtt from 'mqtt';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import ItemsDialog from './pick_item';
import InfoIcon from '@mui/icons-material/Info';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
import CRMViewPhysicalItem from './items/view_physical_item';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
export default function CRMPhysicalItems() {
  const [client, setClient] = useState(null);
  const [mqttConnectionStatus, setMqttConnectionStatus] = useState('Disconnected');
  const [globalFilter, setGlobalFilter] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const mqttChannel = 'device/1678671';
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const [showItemSelection, setShowItemSelection] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openViewPhysicalItem, setOpenViewPhysicalItem] = useState(false);
  const [selectedPhysicalItem, setSelectedPhysicalItem] = useState(null);

  useEffect(() => {
    const initMqttConnection = () => {
      const mqttClient = mqtt.connect('ws://localhost:8083/mqtt');

      mqttClient.on('connect', () => {
        setMqttConnectionStatus('Connected');
        mqttClient.subscribe(mqttChannel);
      });

      mqttClient.on('error', (err) => {
        setMqttConnectionStatus('Connection Error');
        mqttClient.end();
      });

      mqttClient.on('reconnect', () => {
        setMqttConnectionStatus('Reconnecting...');
      });

      mqttClient.on('offline', () => {
        setMqttConnectionStatus('Disconnected');
      });

      mqttClient.on('message', (topic, message) => {
        if (topic === mqttChannel) {
          const data = JSON.parse(message.toString());
          if (data.sun_number) {
            setSunNumber(data.sun_number);
          }
        }
      });

      setClient(mqttClient);

      return mqttClient;
    };

    const mqttClient = initMqttConnection();

    return () => {
      mqttClient.end();
    };
  }, []);
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/Office/CRM/Items/physical_items');
      setItems(response.data);
    } catch (err) {
      setError('Failed to fetch items');
      setSnackbarMessage('Failed to fetch items');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {


    fetchData();
  }, []);

  const handleOpenNewItemDialog = () => {
    setShowNewItemDialog(true);
  };

  const handleCloseNewItemDialog = () => {
    setShowNewItemDialog(false);
    setSelectedItem(null);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setShowItemSelection(false);
  };

  const handleSaveNewItem = async () => {
    if (selectedItem && sunValues.length > 0) {
      const newItem = {
        physical_item_id: items.length + 1, // Replace with actual ID if necessary
        item_id: selectedItem.id, // Use selected item's ID
        sun_values: Object.fromEntries(sunValues.map(({ key, value }) => [key, value])),
      };

      try {
        const response = await axios.post('/Office/CRM/Items/physical_items', newItem);
        if (response.status === 200) {
          fetchData();
          setSnackbarMessage('Item saved successfully!');
          setSnackbarSeverity('success');
        } else if (response.status === 400) {
          setSnackbarMessage(response.data.message || 'Failed to save item');
          setSnackbarSeverity('error');
        }
      } catch (err) {
        setSnackbarMessage(err.response?.data?.message || 'Failed to save item');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        handleCloseNewItemDialog();
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const groupByItemName = (items) => {
    return items.reduce((groups, item) => {
      const group = groups[item.item_name] || [];
      group.push(item);
      groups[item.item_name] = group;
      return groups;
    }, {});
  };

  const groupedItems = groupByItemName(searchFunction(globalFilter, ['item_name', 'sku', 'sun_number'], items));
  const pagedItems = Object.entries(groupedItems).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const [sunValues, setSunValues] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSunValueChange = (index, field, value) => {
    const updatedSunValues = [...sunValues];
    updatedSunValues[index][field] = value;
    setSunValues(updatedSunValues);
  };


  const addSunValue = () => {
    setSunValues([...sunValues, { key: '', value: '' }]);
  };


  const renderNewItemDialog = () => (
    <Dialog open={showNewItemDialog} onClose={handleCloseNewItemDialog} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Add New Physical Item</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3} mt={2}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowItemSelection(true)}
            >
              Select Item
            </Button>
            <Typography variant="body1"><strong>Selected Item:</strong> {selectedItem?.item_name || 'None'}</Typography>
            <Typography variant="body2" color="textSecondary"><strong>SKU:</strong> {selectedItem?.sku || 'N/A'}</Typography>
          </Box>

          <Box >
            <Typography variant="h6" mt={1} mb={2}>
              Additional Details
            </Typography>
            {sunValues.map((item, index) => (
              <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 1 }}>
                <Grid item xs={5}>
                  <TextField
                    label="Key"
                    value={item.key}
                    fullWidth
                    onChange={(e) => handleSunValueChange(index, 'key', e.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Value"
                    value={item.value}
                    fullWidth
                    onChange={(e) => handleSunValueChange(index, 'value', e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => removeSunValue(index)} color="error">
                    <RemoveCircle />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button
              startIcon={<AddCircle />}
              onClick={addSunValue}
              sx={{ color: orangeColor }}
            >
              Add Detail
            </Button>
          </Box>

        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button onClick={handleCloseNewItemDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveNewItem} color="primary" variant="contained" disabled={!selectedItem || sunValues.length == 0}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        marginBottom={2}
        borderRadius={2}
        boxShadow={3}
        bgcolor={orangeColor} // Adjust this or use `theme.palette.background.paper` for a more neutral tone
      >
        <TextField
          label={`Search (MQTT: ${mqttConnectionStatus} on ${mqttChannel})`}
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{
            bgcolor: 'white', // White background to highlight the search bar
            borderRadius: 1, // Smooth edges for the search bar
            marginRight: 2, // Add space between the search bar and button
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenNewItemDialog}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            textTransform: 'none', // No uppercase text for better readability
          }}
        >
          Add Item
        </Button>
      </Box>
      <Box position="relative" width="100%" height="100%">
        <TableContainer component={Paper} sx={{ top: '0', bottom: '0', position: 'absolute' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align='center'>ID</TableCell>
                <TableCell align='center'>Sun Number</TableCell>
                <TableCell align='center'>Created At</TableCell>
                <TableCell align='center'>Created By</TableCell>
                <TableCell align='center'>Current Place</TableCell>
                <TableCell align='center'>Last Place</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={7}>
                        <Skeleton variant="rectangular" height={30} />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                pagedItems.map(([itemName, itemGroup]) => (
                  <React.Fragment key={itemName}>
                    {/* Item Name Header Row */}
                    <TableRow>
                      <TableCell colSpan={7} style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        <Box display="flex" alignItems="center">
                          <ImageIcon style={{ marginRight: 8 }} />
                          <Typography variant="subtitle1" fontWeight="bold">
                            {itemName} (SKU: {itemGroup[0].sku})
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {itemGroup.map((item) => (
                      <TableRow key={item.physical_item_id}>
                        <TableCell>{item.physical_item_id}</TableCell>
                        <TableCell>{typeof item.sun_values == 'object' ? Object.values(item.sun_values)[0] : ''}</TableCell>
                        <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                        <TableCell>{item.created_by}</TableCell>
                        <TableCell>{item.current_place_name}</TableCell>
                        <TableCell>{item.last_place_name}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="info"
                            onClick={() => {
                              setSelectedPhysicalItem(item);
                              setOpenViewPhysicalItem(true);
                            }} // Replace with your logic
                            size="small"
                            sx={{
                              padding: 0, // Remove extra padding
                            }}
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>

      <TablePagination
        sx={{ ...CommonSX, marginTop: '5px' }}
        rowsPerPageOptions={[30, 50, 60]}
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Render New Item Dialog */}
      {renderNewItemDialog()}

      {/* Items Dialog for selecting an item */}
      <ItemsDialog open={showItemSelection} onClose={handleItemSelect} />

      {/* Snackbar for showing success or error messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CRMViewPhysicalItem open={openViewPhysicalItem} close={() => setOpenViewPhysicalItem(false)} physicalItemID={selectedPhysicalItem?.physical_item_id} />
    </Box>
  );
}

