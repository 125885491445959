import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  LinearProgress,
  Alert,
  IconButton,
  Typography,
} from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import axios from 'axios';
import { orangeColor } from '../../../css/common_sx';

export default function CRMViewPhysicalItem({ open, close, physicalItemID }) {
  const [formData, setFormData] = useState(new FormData());
  const [sunValues, setSunValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setError(null);
      setLoading(true);
      axios
        .get(`/Office/CRM/Items/physical_items/${physicalItemID}`)
        .then((response) => {
          const data = response.data;
          const form = new FormData();
          Object.keys(data).forEach((key) => form.append(key, data[key]));
          setFormData(form);
          setSunValues(
            Object.entries(data.sun_values || {}).map(([key, value]) => ({ key, value }))
          );
          setLoading(false);
        })
        .catch(() => {
          setError('Failed to fetch data');
          setLoading(false);
        });
    }
  }, [open, physicalItemID]);

  const handleSunValueChange = (index, field, value) => {
    const updatedSunValues = [...sunValues];
    updatedSunValues[index][field] = value;
    setSunValues(updatedSunValues);
  };

  const addSunValue = () => {
    setSunValues([...sunValues, { key: '', value: '' }]);
  };

  const removeSunValue = (index) => {
    const updatedSunValues = sunValues.filter((_, i) => i !== index);
    setSunValues(updatedSunValues);
  };

  const handleSave = () => {
    const updatedData = {
      ...Object.fromEntries(formData.entries()),
      sun_values: Object.fromEntries(sunValues.map(({ key, value }) => [key, value])),
    };
    axios.put(`/Office/CRM/Items/physical_items/${physicalItemID}`, updatedData)
      .then((res) => {
        if (res.status === 200) {
          close();
        }
      })
      .catch((err) => { });
    console.log('Save Data:', updatedData);
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <DialogTitle bgcolor={orangeColor} color="#fff">
        View Physical Item
      </DialogTitle>
      <DialogContent sx={{ marginBottom: '5px' }}>
        {loading && <LinearProgress />}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Item ID"
                  value={formData.get('item_id') || ''}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Item Name"
                  value={formData.get('item_name') || ''}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="SKU"
                  value={formData.get('sku') || ''}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Created At"
                  value={new Date(formData.get('created_at') || '').toLocaleString()}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Created By"
                  value={formData.get('created_by') || ''}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" mt={1} mb={2}>
                  Additional Details
                </Typography>
                {sunValues.map((item, index) => (
                  <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 1 }}>
                    <Grid item xs={5}>
                      <TextField
                        label="Key"
                        value={item.key}
                        fullWidth
                        onChange={(e) => handleSunValueChange(index, 'key', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label="Value"
                        value={item.value}
                        fullWidth
                        onChange={(e) => handleSunValueChange(index, 'value', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeSunValue(index)} color="error">
                        <RemoveCircle />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddCircle />}
                  onClick={addSunValue}
                  sx={{ color: orangeColor }}
                >
                  Add Detail
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={formData.get('description') || ''}
                  onChange={(e) => setFormData('description', e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={close}
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            marginRight: 2,
            bgcolor: orangeColor,
            color: '#fff',
            borderRadius: 2,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

