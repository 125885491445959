import {
  Box,
  TextField,
  Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { InfoOutlined } from '@mui/icons-material';

export default function ItemsTable(
  {
    items,
    onFieldChange, onRemove,
    requireAmount, allowDecrement = true,
    showWarehouseName = false,
    allowRemove = true,
    allowAmountChange = true,
    onClickPhysicalItem = null,
    onClickItemInfo = null
  }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align='center'>Item Name</TableCell>
            {showWarehouseName && <TableCell align='center'>Warehouse</TableCell>}
            <TableCell align="center">Quantity</TableCell>
            {requireAmount && (
              <>
                <TableCell align="center">Net / q</TableCell>
                <TableCell align="center">VAT / q</TableCell>
                <TableCell align="center">Gross / q</TableCell>
                <TableCell align="center">Total</TableCell>
              </>
            )}
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => {
            const havePhysicalItems = item.physical_items && item.physical_items.length > 0;
            return (
              <TableRow key={item.id}>
                <TableCell align='center' sx={{ alignContent: 'center' }}> {item.item_name}</TableCell>
                {showWarehouseName && <TableCell>{item.warehouse_name}</TableCell>}
                <TableCell align="center">
                  {allowDecrement ? (
                    <TextField
                      size='small'
                      type="number"
                      value={item.quantity}
                      disabled={!allowDecrement}
                      onChange={(e) => onFieldChange(index, 'quantity', parseInt((e.target.value, '')) < 1 ? 1 : parseInt(e.target.value))}
                      fullWidth
                      sx={{ width: '80px' }} // Adjust width as needed
                    />) : (
                    <Typography variant='body1' color={item.quantity > 0 ? 'green' : 'red'} >{item.quantity}</Typography>
                  )}
                </TableCell>
                {requireAmount && (
                  <>
                    <TableCell align="center">
                      <TextField
                        size='small'
                        type="number"
                        value={item.net}
                        disabled={!allowAmountChange}
                        onChange={(e) => onFieldChange(index, 'net', e.target.value)}
                        fullWidth
                        sx={{ width: '80px' }} // Adjust width as needed
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        type="number"
                        size='small'
                        value={item.vat}
                        disabled={!allowAmountChange}
                        onChange={(e) => onFieldChange(index, 'vat', e.target.value)}
                        fullWidth
                        sx={{ width: '80px' }} // Adjust width as needed
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        size='small'
                        type="number"
                        value={item.gross}
                        disabled={!allowAmountChange}
                        onChange={(e) => onFieldChange(index, 'gross', e.target.value)}
                        fullWidth
                        sx={{ width: '80px' }} // Adjust width as needed
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        size='small'
                        type="number"
                        value={item.total}
                        disabled={true}
                        fullWidth
                        sx={{ width: '80px' }} // Adjust width as needed
                      />
                    </TableCell>

                  </>
                )}
                <TableCell align="center">
                  <Button size='small' disabled={!allowRemove} color="secondary" onClick={() => onRemove(index)}>
                    Remove
                  </Button>
                  {havePhysicalItems && (
                    <IconButton size='small' color="primary" disabled="true">
                      <KeyIcon />
                    </IconButton>
                  )}
                  <IconButton size='small' color="primary" onClick={() => {
                    if (onClickItemInfo) onClickItemInfo(item);
                  }}>
                    <InfoOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

