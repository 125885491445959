import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Alert,
  TextField,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { debounce } from 'lodash';
import { searchFunction } from "../../common/search_function";
import { TaxiAlertRounded } from "@mui/icons-material";
import BusinessIcon from '@mui/icons-material/Business';
import HouseIcon from '@mui/icons-material/House';
const PropertiesPhysicalTable = ({ onSelect }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [globalSearch, setGlobalSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get("/StaffGetProperties/physicals");
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch properties data.");
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const handleRowSelect = (row) => {
    setSelectedRow(row);
    if (onSelect) onSelect(row);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const filteredData = useMemo(() => {
    return searchFunction(globalSearch,
      ['name', 'full_address', 'c_alias_id'], data)
  }, [globalSearch, data]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  // Paginate the filtered data
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (loading) {
    return (
      <Box sx={{ padding: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        onChange={(e) => handleSearchChange(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Box overflow='auto' maxHeight='50vh'>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Alias</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Full Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((property) => (
                <TableRow
                  key={property.id}
                  hover
                  selected={selectedRow?.id === property.id}
                  onClick={() => handleRowSelect(property)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      selectedRow?.id === property.id ? "#f5f5f5" : "inherit",
                  }}
                >
                  <TableCell>
                    {(() => {
                      switch (parseInt(property.type_id)) {
                        case 1:
                          return <HouseIcon />;
                        case 2:
                          return <BusinessIcon />
                        default:
                          return "Unknown";
                      }
                    })()}
                  </TableCell>
                  <TableCell>{property.c_alias_id}</TableCell>
                  <TableCell>{property.name}</TableCell>
                  <TableCell>{property.full_address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PropertiesPhysicalTable;

