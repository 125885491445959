import React, { useState, useEffect } from "react";
import { createBrowserRouter, Routes, Route, Navigate, RouterProvider, useLocation } from "react-router-dom";
import Login from "./Login";
import axios from 'axios';
import Tabholder from "./tabholder"
import MonitoringDashboard from "./screens/dashboards/monitoring_dash";
import Checkinout from "./screens/dashboards/inouts_per_days";
import { setupAxios } from "./common/setupaxios";
import ReactGA from 'react-ga';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: isLoggedIn ? <Navigate to="/home" /> : <Login onLogin={() => setIsLoggedIn(true)} />,
    },
    {
      path: "/home",
      element: isLoggedIn ? <Tabholder /> : <Navigate to="/login" /> // <Login onLogin={() => setIsLoggedIn(true)} />,
    },
    {
      path: "/dashs/mon",
      element: <MonitoringDashboard />
    },
    {
      path: "/dashs/inouts",
      element: <Checkinout />
    },
    {
      path: "/dashs/ins",
      element: <Checkinout showCheckouts={false} />
    }, {
      path: "/dashs/outs",
      element: <Checkinout showCheckins={false} />
    }

  ]);


  useEffect(() => {
    setupAxios();
    ReactGA.initialize('G-JNCKDHEJ7P', { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <RouterProvider router={router} />
  )
}

export default App;

