import React, { useEffect, useState } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import { orangeColor } from '../../css/common_sx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Info from '@mui/icons-material/Info';
export default function MonitoringDPI({ visible, device }) {
  const [dpis, setDpis] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fetchDpis = async () => {
    try {
      const response = await axios.get(`/LeviathanMonitoring/Devices/${device.id}/dpis`);
      setDpis(response.data);
      const responseFiles = await axios.get(`/LeviathanMonitoring/Devices/${device.id}/files`);
      setFiles(responseFiles.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saveKeyValue = async (id, newValue) => {
    try {
      await axios.put(`/LeviathanMonitoring/Devices/${device.id}/dpis/${id}`, { key_value: newValue });
      setDpis((prev) =>
        prev.map((dpi) => (dpi.id === id ? { ...dpi, key_value: newValue } : dpi))
      );
      setEditingRow(null);
      setTempValue('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDpis();
  }, [visible, device]);

  const columns_dpis = ['ID', 'DPI Name', 'DPI Value', 'Files', {
    label: 'Actions', actions: [
      { icon: AddIcon, onClick: () => { } }
    ]
  }];
  const columns_files = ['ID', 'File Name', 'Cluster ID', 'Actions'];

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={2}>
        <TableGrid columns={columns_dpis} loading={loading} rowsPerPage={10}>
          {dpis.map((dpi) => (
            <TableRow key={dpi.id}>
              <TableCell>{dpi.id}</TableCell>
              <TableCell>{dpi.key_ident}</TableCell>
              <TableCell
                onDoubleClick={() => {
                  setEditingRow(dpi.id);
                  setTempValue(dpi.key_value);
                }}
              >
                {editingRow === dpi.id ? (
                  <Box display="flex" justifyContent='center'>
                    <TextField
                      value={tempValue}
                      onChange={(e) => setTempValue(e.target.value)}
                      size="small"
                      variant="outlined"
                    />
                    <IconButton
                      onClick={() => saveKeyValue(dpi.id, tempValue)}
                      size="small"
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Box>
                ) : (
                  dpi.key_value
                )}
              </TableCell>
              {/*<TableCell>
                <Autocomplete
                  multiple
                  options={files}
                  getOptionLabel={(file) => file.file_name}
                  value={dpi.files || []}
                  onChange={(event, newValue) => handleFileSelectionChange(dpi.id, newValue)}
                  isOptionEqualToValue={(option, value) => option.file_id === value.file_id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Files"
                      InputProps={{ ...params.InputProps, style: { fontSize: '0.8rem' } }}
                    />
                  )}
                  sx={{ '.MuiAutocomplete-input': { fontSize: '0.8rem' }, '.MuiAutocomplete-endAdornment': { height: '1rem' } }}
                />
              </TableCell>*/}
              <TableCell>{dpi?.files?.map((el) => <Typography>{el.file_name}</Typography>)}</TableCell>
              <TableCell><IconButton sx={{ padding: 0 }} onClick={() => { }}><Info /></IconButton></TableCell>
            </TableRow>
          ))}
        </TableGrid>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        padding={0}
        mb={1}
        mt={1}
        borderRadius={2}
        boxShadow={3}
        bgcolor={orangeColor}
      >
        <Box flex={1}>
          <Typography ml={1} color="white" variant="h4">
            Files
          </Typography>
        </Box>
        <Box justifyContent="right" display="flex" flex={1}>
          <IconButton onClick={() => { }}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
        <TableGrid columns={columns_files} loading={loading} rowsPerPage={10}>
          {files.map((file) => (
            <TableRow key={file.file_id} hover >
              <TableCell>{file.file_id}</TableCell>
              <TableCell>{file.file_name}</TableCell>
              <TableCell>{file.cluster_name}</TableCell>
              <TableCell><Tooltip title="Assign DPI's"><IconButton disabled={
                selectedFile !== null && selectedFile.file_id !== file.file_id
              } sx={{ padding: 0 }} onClick={() => { setSelectedFile(file) }}><ArrowForwardIcon /></IconButton></Tooltip></TableCell>
            </TableRow>
          ))}
        </TableGrid>
      </Box>
    </Box>
  );
}
