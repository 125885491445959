import {
  Box,
  TextField,
  Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { InfoOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '@mui/icons-material/Delete';
export default function PhysicalItemsTable({ items, allowAdd = false, onAddNewPhysicalItem = null, onRemove = null }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align='center'>Serial Number</TableCell>
            <TableCell align='center'>Actions
              {allowAdd && (
                <IconButton onClick={() => {
                  if (onAddNewPhysicalItem) {
                    onAddNewPhysicalItem();
                  }
                }}>
                  <AddIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items ?
            items.map((item, index) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{typeof item.sun_values == 'object' ? Object.values(item.sun_values)[0] : ''}</TableCell>
                  <TableCell align='center'>
                    <IconButton onClick={() => { }}>
                      <InfoOutlined />
                    </IconButton>
                    {allowAdd && (
                      <IconButton onClick={() => {
                        if (onRemove) {
                          onRemove(item);
                        }
                      }}>
                        <TrashIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            }) : null}
        </TableBody>
      </Table>
    </TableContainer>

  )
}
