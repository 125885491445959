import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar, Alert, TableRow, TableCell
} from '@mui/material';
import { orangeColor } from '../../../css/common_sx';
import { DialogSaveButton, DialogCancelButton } from '../../../common/DialogButtons';
import { TableGrid } from '../../../common/TableGrid';
export default function AddNewDeviceDialog({ open, onClose, onNewDevice }) {
  const [loading, setLoading] = useState(false);
  const [interfaceList, setInterfaceList] = useState([
    { interface: 'eth0', ip: '1', netmask: '1', gateway: '1', mac: '1' },
    { interface: 'eth1', ip: '1', netmask: '1', gateway: '1', mac: '1' },
    { interface: 'eth2', ip: '1', netmask: '1', gateway: '1', mac: '1' },
  ]);

  useEffect(() => {
    if (open) {
    }
  }, [open]);

  const columns_ips = ['Interface', 'IP Address', 'Netmask', 'Gateway', 'MAC'];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ bgcolor: orangeColor }}>Add New Device</DialogTitle>
      <DialogContent >
        <Box p={1}>
          <Typography variant="h6" gutterBottom> Device Information </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Device Name"
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom> Device Network Information </Typography>
          <Box width="100%" flex="1" height='150px' display="flex" flexDirection="column">
            <TableGrid columns={columns_ips} rowsPerPage={10} loading={loading} enableOuterShell={false}>
              {interfaceList.map((interfaceItem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{interfaceItem.interface}</TableCell>
                    <TableCell>{interfaceItem.ip}</TableCell>
                    <TableCell>{interfaceItem.netmask}</TableCell>
                    <TableCell>{interfaceItem.gateway}</TableCell>
                    <TableCell>{interfaceItem.mac}</TableCell>
                  </TableRow>);
              })}
            </ TableGrid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => { }} />
        <DialogSaveButton onClick={() => { }} />
      </DialogActions>
    </Dialog >)
};
